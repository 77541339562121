<template>
  <div class="blog-wrapper p-1">
    <b-link href="/">
      <b-col cols="12" class="mt-3 text-center">
        <b-link href="/">
          <b-img
            :src="require('@/assets/images/avatars/optimalfull.png')"
            style="width: 200px"
            class="mb-1"
          />
        </b-link>
      </b-col>
      <b-col cols="12" class="text-center">
        <h2 class="text-danger">
          <b>E-Learning Optimal</b>
        </h2>
        <b-card-text class="mb-3"> </b-card-text>
      </b-col>
    </b-link>
    <br />
    <br />
    <b-row>
      <b-col sm="1"></b-col>
      <b-col sm="10">
        <section class="itemView">
          <b-row class="match-height">
            <b-col sm="6">
              <b-link href="/register">
                <BCard class="mb-2" style="background-color: antiquewhite">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="primary">
                        <feather-icon size="24" icon="LockIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto text-center">
                      <h1 class="font-weight-bolder mb-0">Login Optimal</h1>
                    </b-media-body>
                  </b-media>
                </BCard>
              </b-link>
            </b-col>
            <b-col sm="6">
              <b-link href="/login">
                <BCard class="mb-2" style="background-color: antiquewhite">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="primary">
                        <feather-icon size="24" icon="LogInIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto text-center">
                      <h1 class="font-weight-bolder mb-0">Register Optimal</h1>
                    </b-media-body>
                  </b-media>
                </BCard>
              </b-link>
            </b-col>
          </b-row>
        </section>
      </b-col>
    </b-row>
  </div>
</template>
  
  <script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
  },
  data() {
    return {
      search_query: "",
      currentPage: 1,
      perPage: 1,
      rows: 50,
      dataProduct: [],
      level: localStorage.getItem("Ulevel"),
      selected: "left",
      selected2: "right",
      getUrl: "",
    };
  },
  async mounted() {
    await this.getData();
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    async getData() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "products/view")
        .then((res) => {
          this.dataProduct = res.data.data;
        });
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
</script>
  
  <style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

  